import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NewSessionDetected } from '../../constants/cobrowse-config';
import { HttpWrapperService } from '../http-service/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class CobrowseService {

  constructor(private httpClientWrapper: HttpWrapperService) { }

  emitSessionDetectedForRecording(sessionDetected: NewSessionDetected): Observable<any> {
    const { domain_userid, domain_sessionid, tenantid, guest_hostname } = sessionDetected
    return this.httpClientWrapper.post('newSessionDetected', { domain_userid, domain_sessionid, tenantid, guest_hostname }, {})
      .pipe(map((obj: any) => obj?.channel))
      .pipe(catchError(e => throwError(e)));
  }
}
