import { RoutesUrls } from '../../../../core/constants/common.enum';
import { PluginStates } from 'src/app/core/constants/common.enum';
import { SnowplowService } from '../../../../core/services/snowplow/snowplow.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { Constants } from '../../../../core/constants/constant';
import { AgentResponseData, AgentStatus } from '../../../../core/constants/agent.modal';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScheduledInfoCreate } from 'src/app/core/constants/booking-appointment.modal';
import * as moment from 'moment';
declare var require: any;
const ics = require('ics');
import { Router } from '@angular/router';
import { SharedService } from '../../../../core/services/shared/shared.service';
import { GtmTrackers } from '../../../../core/constants/trackerLabels';
import { GtmService } from '../../../../core/services/gtm/gtm.service';

@Component({
  selector: 'app-schedule-appointment-success',
  templateUrl: './schedule-appointment-success.component.html',
  styleUrls: ['./schedule-appointment-success.component.scss']
})
export class ScheduleAppointmentSuccessComponent implements OnInit {
  sessionInfo: ScheduledInfoCreate;
  agentDetails: AgentResponseData;
  currentLanguage: string;
  spTrackerLabels = Constants.spTrackerLabels;
  icsFile: string;

  @Input() editForm = false;
  @Input() zone: string;
  @Output() rescheduleOrCancelEvent = new EventEmitter();
  @Output() editEvent = new EventEmitter();

  constructor(private translate: TranslateService,
    private utils: UtilsService,
    private router: Router,
    private snowplowService: SnowplowService,
    private sharedService: SharedService,
    private gtmService: GtmService) {
    this.currentLanguage = this.utils.getLocalVal(PluginStates.language);
  }

  ngOnInit(): void {
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.sessionInfo = this.utils.getLocalVal(PluginStates.bookingSessionInfo, 'response');
    this.icsFile = this.b64DecodeUnicode(this.sessionInfo?.ics_file);
    const currentLocale = this.utils.checkAndGetCurrentLang();
    moment.locale(currentLocale);
    this.snowplowService.trackStructEvent(this.spTrackerLabels.bookingConfirmation, this.spTrackerLabels.screenShown,
      this.editForm ? this.spTrackerLabels.confirmationPageReschedule : this.spTrackerLabels.confirmationPage, this.spTrackerLabels.version, 1);
    this.gtmService.sendGtm(GtmTrackers.optimyBookingCompleted);
  }

  onEditSession() {
    this.editEvent.emit(true);
  }

  onCancelSession(value: string) {
    this.rescheduleOrCancelEvent.emit(value);
  }


  getDateAndTime() {
    const activemonth = moment(this.sessionInfo?.start_dt).format('MMM');
    const activeTime = moment(this.sessionInfo?.start_dt).format('LT');
    const activeDate = moment(this.sessionInfo?.start_dt).format('D');
    const activeYear = moment(this.sessionInfo?.start_dt).format('YYYY');
    return `${activemonth} ${activeDate}, ${activeYear} ${this.translate.instant('common.at')} ${activeTime}.`;
  }

  generateIcs() {
    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob([this.icsFile]));
    downloadLink.download = 'file.ics';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  b64DecodeUnicode(str: string) {
    return decodeURIComponent(atob(str).split('').map(function (c) {
      const codeString = `00${c.charCodeAt(0).toString(16)}`;
      return `%${codeString.slice(-2)}`;
    }).join(''));
  }

  addToGoogle() {
    let summaryAndDesCopy = this.icsFile.split('SUMMARY:')[1].split('DESCRIPTION:');
    const summaryAndDes = summaryAndDesCopy?.map(res=> {
      return res?.replace(/\r\n /g, '')
    })
    const details = encodeURIComponent(summaryAndDes[1]?.split('LOCATION')[0]);
    const startTime = `${moment(this.sessionInfo?.start_dt).utc().format('YYYYMMDDTHHmm')}00Z`;
    const endTime = `${moment(this.sessionInfo?.start_dt).add(this.sessionInfo?.duration, 'minutes').utc().format('YYYYMMDDTHHmm')}00Z`;
    const calendarInfo = `${Constants.googleCalendar}${summaryAndDes[0]}&dates=${startTime}/${endTime}&location=VideoCall&details=${details}`;
    window.open(calendarInfo, '_blank');
  }

  onLinkClick(link: string) {
    window.open(link, '_blank');
  }

  onCloseClick() {
    this.utils?.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.bookingSessionInfo]);
    this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }]);
    this.sharedService.closeBookingModal(true);
  }

}
