import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import {
  GtmTrackers,
  SnowplowTrackerAction,
  SnowplowTrackerCategories,
  SnowplowTrackerLabels,
  SnowplowTrackerProperties
} from 'src/app/core/constants/trackerLabels';
import { ScreenOrientationService } from 'src/app/core/services/utils/screen-orientation.service';
import {
  Context,
  PluginStates,
  QueueUpdateReq,
  RoomInfoStates,
  RoutesUrls
} from '../../../core/constants/common.enum';
import { Constants } from '../../../core/constants/constant';
import { UtilsService } from '../../../core/services/utils/utils.service';
import { VideoCallService } from '../../../core/services/video-call/video-call.service';
import { CallStates, CallStatus, CreateReq } from '../../../core/constants/call.modal';
import { ResizeService } from '../../../core/services/resize/resize.service';
import { SnowplowService } from '../../../core/services/snowplow/snowplow.service';
import { distinctUntilChanged } from 'rxjs/operators';
import {AgentStatus} from '../../../core/constants/agent.modal';
import { GtmService } from '../../../core/services/gtm/gtm.service';

@Component({
  selector: 'app-leave-message-modal',
  templateUrl: './leave-message-modal.component.html',
  styleUrls: ['./leave-message-modal.component.scss']
})
export class LeaveMessageModalComponent implements OnInit, OnDestroy {
  leaveMessageForm: UntypedFormGroup;
  emailValidator = [Validators.pattern(Constants.emailRegex)];
  spTrackerLabels = Constants.spTrackerLabels;
  queuePositionId: number;
  guestToken: string;
  showEmailPhoneError = false;

  public maximize = false;
  currentCategory: string;
  context: Context[];

  @Output() closeModal = new EventEmitter();
  @Input() queueId: number;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService,
    private videoCallService: VideoCallService,
    private screenOrientationService: ScreenOrientationService,
    private snowplowService: SnowplowService,
    private resizeService: ResizeService,
    private gtmService: GtmService) { }

  spTracker = {
    labels: SnowplowTrackerLabels,
    categories: SnowplowTrackerCategories,
    actions: SnowplowTrackerAction,
    properties: SnowplowTrackerProperties
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngOnInit(): void {
   this.guestToken = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestToken);
   this.queuePositionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
   this.context = this.utils.getCallSchemaContext();
   const joinQueueQuestionSchema = this.utils.getJoinQueueQuestionSchema();

   if(joinQueueQuestionSchema){
     this.context = [...this.context, joinQueueQuestionSchema];
   }

    this.leaveMessageForm = this.formBuilder.group({
      name: new UntypedFormControl('', [Validators.required]),
      phone: new UntypedFormControl(''),
      email: new UntypedFormControl('', this.emailValidator),
      message: new UntypedFormControl('')
    });
    this.subscribeToResizeSub();
    this.updateLandscapeInformation();

    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.screenShown,
      this.spTracker.labels.leaveMsg, this.spTracker.labels.version, 1, this.context);

    this.leaveMessageForm.valueChanges
        .pipe(takeUntil(this.destroy$), distinctUntilChanged())
        .subscribe((value) => {
          if (value.email || value.phone) {
            this.showEmailPhoneError = false;
          }
        });
    this.gtmService.sendGtm(GtmTrackers.optimyLeaveMessageStarted);
  }

  checkValidationAndSend() {
    if (this.leaveMessageForm.value.email || this.leaveMessageForm.value.phone) {
      this.executeSubmit();
    } else {
      this.showEmailPhoneError = true;
    }
  }

  setPhoneNumberValue(value: string){
    this.leaveMessageForm.get('phone')?.setValue(value);
  }

  executeSubmit() {
    const phone = this.leaveMessageForm.get('phone')?.value?.replace(/[-]|[ ]/g, '');
    const attr: QueueUpdateReq = {
      guest_hostname: this.utils.removeExtraParamsFormUrl(),
      lang: this.utils.getLocalVal(PluginStates.language),
      guest_locale: navigator.language,
      domain_sessionid: this.utils.getDomainSessionId(),
      customer_contact :  {
        full_name : this.leaveMessageForm.get('name')?.value,
      phone: phone,
        email: this.leaveMessageForm.get('email')?.value
      },
      comment: this.leaveMessageForm.get('message')?.value,
      message_left: 1
    }
    console.log(attr);
    if(this.queueId && !this.guestToken) {
      this.addMissCallQueue(attr);
    } else {
      this.submitForm(JSON.stringify(attr));
    }
  }

  addMissCallQueue(attr: any) {
    this.spinner.show();
    const agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    let req: CreateReq = this.utils.createGuestReqValues(agentDetails?.tenant_id);
    req = {
      ...req,
      tenantid: agentDetails?.tenant_id
    }
    const queueIdFromLocal = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queueId);
    const queueId = queueIdFromLocal ? queueIdFromLocal : this.queueId;
    const createGuest =  this.videoCallService.createGuest(req)
    const createChannel = this.videoCallService.createChannel();
    forkJoin([createGuest, createChannel]).subscribe((res) => {
      const updatedAttr = {
        ...attr,
        room_name: res[1]?.sid
      }
      const positionAddReq = {
        attr: JSON.stringify(updatedAttr),
        status: CallStatus.missed,
      }
      this.videoCallService.addUserToQueue(res[0]?.guesttoken, queueId, positionAddReq).subscribe(()=> {
        this.gtmService.sendGtm(GtmTrackers.optimyLeaveMessageCompleted);
        this.spinner.hide();
        this.toastr.success(this.translate.instant('timeKillers.messageDelivered'));
        this.closeModal.next(true);
      })
    });
  }

  submitForm(attr: string) {
    const req = {
      attr_update: attr,
      status: CallStatus.missed,
    }
    this.spinner.show();
    this.snowplowService.trackStructEvent(this.currentCategory, this.spTracker.labels.submit,
      this.spTracker.labels.leaveMsg, '', this.queuePositionId, this.context);
    this.videoCallService.queuePositionUpdate(this.queuePositionId, this.guestToken, req).subscribe(() => {
      this.gtmService.sendGtm(GtmTrackers.optimyLeaveMessageCompleted);
      this.gtmService.sendGtm(GtmTrackers.optimyLeftQueue);
      this.spinner.hide();
      this.toastr.success(this.translate.instant('timeKillers.messageDelivered'));
      this.closeModal.next(true);
      this.snowplowService.trackStructEvent(this.spTrackerLabels.inboundQueueMultiOption,
          this.spTrackerLabels.screenShown, this.spTrackerLabels.leaveMsg, this.spTrackerLabels.submitToast);
      this.utils.removeLocalStoreVal(Constants.optimyLocalStore, [PluginStates.callInfo, PluginStates.roomInfo, PluginStates.bookingSessionInfo, PluginStates.outboundFirstInitiated]);
      this.router.navigate([{ outlets: { plugin: [RoutesUrls.close] } }])
    })

  }

  close(isCancelButton: boolean) {
    this.snowplowService.trackStructEvent(this.currentCategory, isCancelButton ? this.spTracker.labels.cancel : this.spTracker.labels.close,
      this.spTracker.labels.leaveMsg, '', this.queuePositionId, this.context);
    this.closeModal.next(true);
  }

  private subscribeToResizeSub(): void {
    const lastCallState = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.lastCallState);
    this.maximize = lastCallState === CallStates.maximizeFull || lastCallState === CallStates.maximize;

    this.resizeService.resizeSub.pipe(takeUntil(this.destroy$)).subscribe((resp) => {
      this.maximize = !!resp.isMaximized;
      this.updateLandscapeInformation();
    });
  }

  private updateLandscapeInformation(): void {
    if (this.screenOrientationService.isLandscapeAndMobile) {
      this.currentCategory = this.maximize ? this.spTracker.categories.callFullWaitingLandscape : this.spTracker.categories.callChatWaitingLandscape;
    } else {
      this.currentCategory = this.maximize ? this.spTracker.categories.callFullWaiting : this.spTracker.categories.callChatWaiting;
    }
  }

}
