import { Router } from '@angular/router';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { NudgeContent, PluginStates, RoutesUrls } from 'src/app/core/constants/common.enum';
import { ChatService } from 'src/app/core/services/chat/chat.service';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import { v4 as uuidv4 } from 'uuid';
import { AgentResponseData, AgentStatus } from '../../../../core/constants/agent.modal';
import {
  BubbleChoice,
  CallStatus,
  JoinQueueBubbleActions,
  JoinQueueInterface,
  QueueBubble,
  TimeExceedAction
} from '../../../../core/constants/call.modal';
import {
  ChatMessage,
  ChatRes,
  CustomButton,
  CustomMessageOptions,
  MessageCreateReq,
  RedirectAction
} from '../../../../core/constants/chat.modal';
import { RoomInfo, RoomInfoStates } from '../../../../core/constants/common.enum';
import { SharedService } from '../../../../core/services/shared/shared.service';
import { Constants } from '../../../../core/constants/constant';

interface ConciergeMessage extends MessageCreateReq { }

enum TabUuidKeys {
  tabListKey = 'tab_id_list',
  mainTabIdKey = 'main_tab_id',
  tabId = 'tab_id'
}

@Component({
  selector: 'app-concierge',
  templateUrl: './concierge.component.html',
})
export class ConciergeComponent implements OnInit, OnDestroy {

  channelSid: string;
  guestUserId: string;

  agentDetails: AgentResponseData;
  messageList: ChatRes | undefined;
  joinQueueConfig: JoinQueueInterface;
  roomInfo: RoomInfo;

  defaultMessagesValues: any;
  language: string;
  lastQueueQuestionIndex: number = -1;

  tabUuid: string;
  hasWaitedForMainTab: boolean = false;

  queueInterrupt1: any;
  queueInterrupt2: any;

  firstQueueIdIndex: number;

  destroy$: Subject<boolean> = new Subject<boolean>();

  public openLeaveModal: Subject<void> = new Subject<void>();
  public closeLeaveModal: Subject<void> = new Subject<void>();

  public showLeaveMessage: boolean;
  // TODO: remove extra messages
  // extraMessagesCount = 0;
  // extraMessagesInterval: any;
  constructor(
    private router: Router,
    private utils: UtilsService,
    private chatService: ChatService,
    private sharedService: SharedService) {
    this.chatService.executeButtonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe((actionSelected: CustomButton) => {
        if (this.isActionSelectedAndNotSkip(actionSelected)) {
          this.executeAction(actionSelected);
        } else {
          this.prepareQueueWaitQuestionToSend(actionSelected);
        }
      });
    this.sharedService.showBookingAndCheckPreviousState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((keepBooking: boolean) => {
        if (!keepBooking) {
          this.router.navigate([{ outlets: { 'modal-outlet': null } }], {});
        }
      });
    this.sharedService.agentJoiningCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe((agentJoiningCall: boolean) => {
        if (agentJoiningCall) {
          this.closeLeaveModal.next();
          this.router.navigate([{ outlets: { 'modal-outlet': null } }], {});
          clearTimeout(this.queueInterrupt1);
          clearTimeout(this.queueInterrupt2);
          this.sendAgentJoinMessage();
        }
      });

    this.sharedService.closeCall$
      .pipe(takeUntil(this.destroy$))
      .subscribe((calClosed: boolean) => {
        if (calClosed) {
          this.closeLeaveModal.next();
          clearTimeout(this.queueInterrupt1);
          clearTimeout(this.queueInterrupt2);
          this.router.navigate([{ outlets: { 'modal-outlet': null } }], {});
        }
      });

    this.chatService.startInterruptMessage$
        .pipe(takeUntil(this.destroy$))
        .subscribe((isStart) => {
          if(isStart){
            this.scheduleInterruptMessages();
          }
        });

    this.destroy$.subscribe(() => {
      this.destroy$.unsubscribe();
      clearTimeout(this.queueInterrupt1);
      clearTimeout(this.queueInterrupt2);
      // clearInterval(this.extraMessagesInterval);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  async ngOnInit(): Promise<void> {
    this.agentDetails = this.utils.getSessionVal(AgentStatus.agentStatusInfo);
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    this.joinQueueConfig = this.agentDetails?.join_queue_config as JoinQueueInterface;
    this.language = this.utils.getLocalVal(PluginStates.language);
    this.waitForCallToStart();
  }

  private waitForCallToStart(): void {
    setTimeout(() => {
      this.guestUserId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.guestId);
      this.channelSid = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.roomName);
      this.channelSid = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.roomName);
      const queuePositionId = this.utils.getLocalVal(PluginStates.roomInfo, RoomInfoStates.queuePositionId);
      if (this.guestUserId && this.channelSid && queuePositionId) {
        this.initializeMessages();
      } else {
        this.waitForCallToStart();
      }
    }, 500);
  }

  private async initializeMessages(): Promise<void> {
    await this.updateMessageList();

    this.defaultMessagesValues = {
      identity: 'concierge',
      channelSid: this.channelSid,
    }
    this.checkAndInitializeTabIdentification();

    this.sendHtmlContentOfNudge().then(() => {
      this.sendJoinQueueMessage();
      this.findFirstQuestionWithQueueId();
      setTimeout(() => {
        this.prepareQueueWaitQuestionToSend();
      }, 1000);

      const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
      if(roomInfo?.roomStatus === CallStatus.pending){
        this.scheduleInterruptMessages();
      }
    });
    // TODO: remove extra messages
    // this.scheduleExtraMessages();
  }

  async sendHtmlContentOfNudge(){
    const isNudgeClick = this.utils.getSessionVal(NudgeContent.isNudgeClick);
    if(isNudgeClick){
      const htmlBody = this.utils.getSessionVal(NudgeContent.htmlContent);
      if(htmlBody) {
        let messageRequest: ConciergeMessage = {
          ...this.defaultMessagesValues,
          message: htmlBody,
          attributes: {
            private: true,
            visible_to: ['agents'],
            custom_message: {
              type: CustomMessageOptions.regular,
              event_key: '',
              response_options: [],
            }
          }
        }
        await firstValueFrom(this.chatService.sendChatMessages(messageRequest));
        this.utils.removeSessionStoreVal(Constants.optimySessionStore, [NudgeContent.htmlContent, NudgeContent.isNudgeClick]);
        return;
      };
      this.utils.removeSessionStoreVal(Constants.optimySessionStore, [NudgeContent.isNudgeClick]);
    }
  }


  private checkAndInitializeTabIdentification(): void {
    const currentTabUuid = this.utils.getSessionVal(TabUuidKeys.tabId);
    if (currentTabUuid) {
      this.tabUuid = currentTabUuid;
    } else {
      this.tabUuid = uuidv4();
      this.utils.setSessionVal([TabUuidKeys.tabId], [this.tabUuid]);
    }
    let tabList = this.utils.getLocalVal(PluginStates.roomInfo, TabUuidKeys.tabListKey) as string[];
    if (!tabList) {
      tabList = [];
    }
    if (!Array.isArray(tabList)) {
      tabList = [tabList];
    }
    if (tabList.includes(this.tabUuid)) {
      return;
    } else {
      tabList.push(this.tabUuid);
      this.utils.setLocalVal(PluginStates.roomInfo, [TabUuidKeys.tabListKey], [tabList]);
      this.utils.setLocalVal(PluginStates.roomInfo, [TabUuidKeys.mainTabIdKey], [this.tabUuid]);
    }
  }

  private prepareQueueWaitQuestionToSend(responseSelected?: CustomButton): void {
    if (!this.joinQueueConfig.queue_wait_questions) return;
    const skipToQuestion = responseSelected?.skip_to_question;

    if(this.lastQueueQuestionIndex === this.firstQueueIdIndex){
      if(responseSelected?.nextQuestionDetails?.success){
        this.scheduleInterruptMessages();
      }
    }
    if(responseSelected?.nextQuestionDetails){
      this.lastQueueQuestionIndex = responseSelected?.nextQuestionDetails?.nextQuestionIndex - 1;
      this.sendQueueWaitMessage(this.joinQueueConfig.queue_wait_questions[this.lastQueueQuestionIndex]);
      return;
    }
    if (skipToQuestion && skipToQuestion > 0) {
      this.lastQueueQuestionIndex = skipToQuestion - 1;
      this.sendQueueWaitMessage(this.joinQueueConfig.queue_wait_questions[skipToQuestion - 1]);
    } else if (skipToQuestion === 0 || (this.lastQueueQuestionIndex + 1 >= this.joinQueueConfig?.queue_wait_questions?.length)) {
      this.sendThankYouMessage();
    } else {
      this.sendQueueWaitMessage(this.joinQueueConfig.queue_wait_questions[++this.lastQueueQuestionIndex]);
    }
  }

  findFirstQuestionWithQueueId(){
     const index: any = this.joinQueueConfig?.queue_wait_questions?.findIndex((value)=> value?.choices && value.choices[0].queue_id)
    this.firstQueueIdIndex = index;
  }

  private isActionSelectedAndNotSkip(responseSelected: CustomButton): boolean {
    return !!responseSelected?.action &&
      responseSelected?.action !== TimeExceedAction.skip;
  }

  private executeAction(responseSelected: CustomButton): void {
    const action = responseSelected?.action;
    switch (action) {
      case TimeExceedAction.booking:
        this.router.navigate([{ outlets: { 'modal-outlet': [RoutesUrls.live_call, 'booking'] } }], {});
        break;
      case TimeExceedAction.leaveMessage:
        this.showLeaveMessage = true;
        this.openLeaveModal.next();
        break;
      default:
        console.log('action not registered:', action);
        break;
    }
  }

  private sendThankYouMessage(): void {
    let thankYouMessage: ConciergeMessage = {
      ...this.defaultMessagesValues,
      message: this.getTextFromCurrentLanguage(this.joinQueueConfig.queue_thank?.text),
      attributes: {
        private: true,
        visible_to: this.getVisibilityInfo(),
        custom_message: {
          type: CustomMessageOptions.regular,
          event_key: this.joinQueueConfig.queue_thank?.event_key,
          response_options: [],
        }
      }
    };
    this.sendConciergeMessage(thankYouMessage);
  }

  private getTextFromCurrentLanguage(text: { [key in string]: string } | undefined): string {
    if (!text) return '';
    return text[this.language] || text.en;
  }

  private sendQueueWaitMessage(message: QueueBubble): void {
    if (!message) return;
    const queueWaitMessage: ConciergeMessage = {
      ...this.defaultMessagesValues,
      message: this.getTextFromCurrentLanguage(message.text),
      attributes: {
        private: true,
        visible_to: this.getVisibilityInfo(),
        custom_message: {
          type: CustomMessageOptions.multiple_choice,
          event_key: message.event_key,
          response_options: this.getChoices(message.choices),
        }
      }
    };
    this.sendConciergeMessage(queueWaitMessage);
  }

  private scheduleInterruptMessages() {
    this.queueInterrupt1 = setTimeout(() => {
      const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
      if(roomInfo?.roomStatus === CallStatus.pending) {
        const queueInterruptMessage: ConciergeMessage = {
          ...this.defaultMessagesValues,
          message: this.getTextFromCurrentLanguage(this.joinQueueConfig.queue_interrupt_1.text),
          attributes: {
            private: true,
            visible_to: this.getVisibilityInfo(),
            custom_message: {
              type: CustomMessageOptions.multiple_choice,
              event_key: this.joinQueueConfig.queue_interrupt_1.event_key,
              response_options: this.getChoices(this.joinQueueConfig.queue_interrupt_1.choices),
            }
          }
        };
        this.sendConciergeMessage(queueInterruptMessage);
      }
    }, 1000 * (this.joinQueueConfig.queue_interrupt_1.appear_delay || 0));

    this.queueInterrupt2 = setTimeout(() => {
      const roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
      if(roomInfo?.roomStatus === CallStatus.pending) {
        const queueInterruptMessage: ConciergeMessage = {
          ...this.defaultMessagesValues,
          message: this.getTextFromCurrentLanguage(this.joinQueueConfig.queue_interrupt_2.text),
          attributes: {
            private: true,
            visible_to: this.getVisibilityInfo(),
            custom_message: {
              type: CustomMessageOptions.multiple_choice,
              event_key: this.joinQueueConfig.queue_interrupt_2.event_key,
              response_options: this.getChoices(this.joinQueueConfig.queue_interrupt_2.choices),
            }
          }
        };
        this.sendConciergeMessage(queueInterruptMessage);
      }
    }, 1000 * (this.joinQueueConfig.queue_interrupt_2.appear_delay || 0));
  }

  // // TODO: remove extra messages
  // private scheduleExtraMessages() {
  //   this.extraMessagesInterval = setInterval(() => {

  //     const message: ConciergeMessage = {
  //       ...this.defaultMessagesValues,
  //       message: `This is an extra message for testing, and the number of this message is ${this.extraMessagesCount}`,
  //       attributes: {
  //         private: true,
  //         visible_to: this.getVisibilityInfo(),
  //         custom_message: {
  //           type: CustomMessageOptions.multiple_choice,
  //           event_key: `extra_message_${this.extraMessagesCount}`,
  //           response_options: [],
  //         }
  //       }
  //     };
  //     if (this.extraMessagesCount > 10) {
  //       message.message = 'Extra messages are stopped';
  //       clearInterval(this.extraMessagesInterval);
  //     }
  //     this.sendConciergeMessage(message);
  //     this.extraMessagesCount++;
  //   }, 2000);
  // }

  private getVisibilityInfo(): string[] {
    return [this.guestUserId, 'agents'];
  }

  private sendJoinQueueMessage() {
    let joinQueueMessage: ConciergeMessage = {
      ...this.defaultMessagesValues,
      message: this.getTextFromCurrentLanguage(this.joinQueueConfig.queue_join.text),
      attributes: {
        private: true,
        visible_to: this.getVisibilityInfo(),
        custom_message: {
          type: CustomMessageOptions.regular,
          event_key: this.joinQueueConfig.queue_join.event_key,
          response_options: [],
        }
      }
    };
    this.sendConciergeMessage(joinQueueMessage);
  }

  private sendAgentJoinMessage() {
    let agentJoinMessage: ConciergeMessage = {
      ...this.defaultMessagesValues,
      message: this.getTextFromCurrentLanguage(this.joinQueueConfig.queue_agent_join.text),
      attributes: {
        private: true,
        visible_to: this.getVisibilityInfo(),
        custom_message: {
          type: CustomMessageOptions.regular,
          event_key: this.joinQueueConfig.queue_agent_join.event_key,
          response_options: [],
        }
      }
    };
    this.sendConciergeMessage(agentJoinMessage);
  }

  private getChoices(choices: BubbleChoice[] | undefined): CustomButton[] {
    const customButtons: CustomButton[] = [];
    choices?.forEach((choice: BubbleChoice) => {
      let button: any = {
        text: this.getTextFromCurrentLanguage(choice.text),
        type: choice.type || 'stroked'
      };
      if (choice.action) {
        button.action = choice.action;
        if(button.action === RedirectAction.redirect && choice?.url){
          button.url = choice.url;
        } else if(button.action === TimeExceedAction.booking && this.agentDetails?.booking_url){
          button.url = this.agentDetails?.booking_url;
        }
      }
      if (choice.queue_id) {
        button.queue_id = choice.queue_id;
      }
      if (choice.skip_to_question || choice.skip_to_question === 0) {
        button.skip_to_question = choice.skip_to_question;
      }
      if(choice?.skip_to_question_fail){
        button.skip_to_question_fail = choice.skip_to_question_fail;
      }
      customButtons.push(button);
    });
    return customButtons;
  }

  private async updateMessageList(): Promise<void> {
    this.messageList = await firstValueFrom(this.chatService.getChatMessage(this.channelSid));
    // TODO: comment this before committing;
    // console.log('this.messageList', this.messageList);
  }

  private async sendConciergeMessage(messageRequest: ConciergeMessage): Promise<boolean> {
    await this.updateMessageList();
    if (this.canSendMessage(messageRequest.attributes?.custom_message?.event_key)) {
      if (this.isThisTabTheMainTab()) {
        await firstValueFrom(this.chatService.sendChatMessages(messageRequest));
        await this.updateMessageList();
        return true;
      } else {
        if (this.hasWaitedForMainTab) {
          this.updateMainTab();
          this.sendConciergeMessage(messageRequest);
          this.hasWaitedForMainTab = false;
        } else {
          setTimeout(() => {
            this.hasWaitedForMainTab = true;
            this.sendConciergeMessage(messageRequest);
          }, 3000);
        }
      }
    }
    return false;
  }

  private updateMainTab() {
    const mainTabId = this.utils.getLocalVal(PluginStates.roomInfo, TabUuidKeys.mainTabIdKey) as string;
    const tabList = this.utils.getLocalVal(PluginStates.roomInfo, TabUuidKeys.tabListKey) as string[];
    if (tabList?.includes(mainTabId)) {
      tabList.splice(tabList.indexOf(mainTabId), 1);
      this.utils.setLocalVal(PluginStates.roomInfo, [TabUuidKeys.tabListKey], [tabList]);
    }
    this.utils.setLocalVal(PluginStates.roomInfo, [TabUuidKeys.mainTabIdKey], [this.tabUuid]);
  }

  private isThisTabTheMainTab(): boolean {
    const mainTabId = this.utils.getLocalVal(PluginStates.roomInfo, TabUuidKeys.mainTabIdKey) as string;
    return mainTabId === this.tabUuid;
  }

  private canSendMessage(eventKey: string | undefined): boolean {
    if (!eventKey) {
      console.log('Message eventKey is undefined');
      return false;
    }
    // Need the message only on inbound call
    this.roomInfo = this.utils.getLocalVal(PluginStates.roomInfo);
    if (this.roomInfo?.roomStatus &&
      (this.roomInfo?.roomStatus === CallStatus.ad_hoc
        || this.isPendingAndScheduleCall()) ||
        (this.roomInfo?.roomStatus === CallStatus.in_progress &&
            eventKey !== JoinQueueBubbleActions.queueAgentJoin)) {
      return false;
    }
    if (!this.messageList) return true;
    const indexOfMessage = this.messageList?.message.findIndex((item: ChatMessage) => {
      return item.attributes?.custom_message?.event_key === eventKey;
    });
    return indexOfMessage === -1;
  }

  private isPendingAndScheduleCall(): boolean {
    return this.roomInfo?.roomStatus === CallStatus.pending && !!this.roomInfo?.startDt;
  }

  closingLeaveMessageModal() {
    this.closeLeaveModal.next();
    this.showLeaveMessage = false;
  }

  @HostListener('document:visibilitychange')
  public updateCurrentTab() {
    if (!document.hidden) {
      const currentUuid = this.utils.getSessionVal(TabUuidKeys.tabId);
      this.utils.setLocalVal(PluginStates.roomInfo, [TabUuidKeys.mainTabIdKey], [currentUuid]);
      const tabList = this.utils.getLocalVal(PluginStates.roomInfo, TabUuidKeys.tabListKey) as string[];
      if (tabList && !tabList.includes(currentUuid)) {
        tabList.push(currentUuid);
        this.utils.setLocalVal(PluginStates.roomInfo, [TabUuidKeys.tabListKey], [tabList]);
      }
    }
  }

  @HostListener('window:beforeunload')
  public removeTabIdFromListBeforeUnload() {
    const tabList = this.utils.getLocalVal(PluginStates.roomInfo, TabUuidKeys.tabListKey) as string[];
    if (tabList?.includes(this.tabUuid)) {
      tabList.splice(tabList.indexOf(this.tabUuid), 1);
      this.utils.setLocalVal(PluginStates.roomInfo, [TabUuidKeys.tabListKey], [tabList]);
    }
    const mainTabId = this.utils.getLocalVal(PluginStates.roomInfo, TabUuidKeys.mainTabIdKey) as string;
    if (mainTabId === this.tabUuid) {
      this.utils.setLocalVal(PluginStates.roomInfo, [TabUuidKeys.mainTabIdKey], [tabList[0]]);
    }
  }
}
