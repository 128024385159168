<div class="leave-form-div">
    <ngx-spinner [fullScreen]="false" type="ball-pulse-sync" size="default"></ngx-spinner>
    <div class="title op-mt-3" [innerHTML]="'timeKillers.leaveFormTitle' | translate"></div>
    <form [formGroup]="leaveMessageForm" class="leave-form">
        <div class="form-field op-my-3">
            <label [innerHTML]="'timeKillers.name' | translate"></label>
            <input formControlName="name" [placeholder]="'timeKillers.name' | translate">
            <small class="text-danger"
                   *ngIf="leaveMessageForm.get('name')?.errors &&
                                           leaveMessageForm.get('name')?.invalid &&
                                           leaveMessageForm.get('name')?.touched" [innerHTML]="'timeKillers.nameError' | translate">
            </small>
        </div>
        <div class="form-field op-my-3">
            <label [innerHTML]="'timeKillers.phone' | translate"></label>
            <div class="intel-phone">
                <app-intel-tell-input (sendPhoneNumber)="setPhoneNumberValue($event)"></app-intel-tell-input>
            </div>
        </div>
        <div class="form-field op-my-3"  >
            <label [innerHTML]="'timeKillers.email' | translate"></label>
            <input formControlName="email" [placeholder]="'timeKillers.email' | translate">
            <small class="text-danger"
                   *ngIf="leaveMessageForm.get('email')?.errors &&
                                           leaveMessageForm.get('email')?.invalid &&
                                           leaveMessageForm.get('email')?.touched"
                   [innerHTML]="'timeKillers.emailError' | translate">
            </small>
        </div>
        <div class="form-field op-my-3">
            <label [innerHTML]="'timeKillers.message' | translate"></label>
            <textarea formControlName="message" [placeholder]="'timeKillers.message' | translate"></textarea>
        </div>

        <div class="op-d-flex op-flex-column">
            <span class="text-danger op-mb-2" [innerHTML]="'timeKillers.emailPhoneError' | translate"
                  *ngIf="showEmailPhoneError"></span>
            <button class=" op-btn op-btn-primary op-btn-flat op-flex-1"
                    [disabled]="leaveMessageForm.invalid" (click)="checkValidationAndSend()"
                    [innerHTML]="'common.submit' | translate"></button>
            <button class="op-mt-3 op-btn op-btn-basic op-btn-danger"
                    [innerHTML]="'common.cancel' | translate" (click)="close(true)"></button>
        </div>
    </form>
    <div class="op-check-test" style="display:none;"></div>
</div>
