import { MessageStatus } from 'src/app/components/video-call/models/chatModels';
import { TimeExceedAction } from './call.modal';
export interface ChatRes {
    message: ChatMessage[];
    nextPageUrl?: string
}

export interface ChatMessage {
    accountSid?: string;
    attributes: MessageAttribute;
    body: string;
    conversationSid?: string;
    dateCreated?: string;
    dateUpdated?: string;
    author: string;
    index?: number;
    lastUpdatedBy?: string;
    media?: any;
    participantSid?: string;
    sid?: string;
    url?: string;
    updatedTime?: string;
    showMessage?: boolean;
    participantName?: string;
    showCustomOptions?: boolean;
}

export interface MessageCreateReq {
    channelSid: string;
    message: string;
    identity: string;
    attributes?: MessageAttribute;
}

export interface MessageUpdateReq {
    channelSid: string;
    message: string;
    identity: string;
    chatServiceSid: string;
    attributes?: MessageAttribute;
}

export interface InviteReq {
    send_to: string;
    invite_code: string;
}

export interface SendToList {
    link_type: string;
    [key: string]: string;
}

export interface GuestsInfo {
    email: string;
    name?: string;
}

export interface TypingInfo {
    isTyping: boolean;
    channelId: string;
    identity: string;
}

export interface ConversationWebHookMessage {
    AccountSid: string;
    Attributes: MessageAttribute;
    Author: string;
    Body: string;
    ClientIdentity?: string;
    ChatServiceSid: string;
    ConversationSid: string;
    DateCreated: string;
    EventType: string;
    Index: string;
    MessageSid: string;
    RetryCount: string;
    ParticipantSid?: string;
    MessagingServiceSid: string;
    RoleSid?: string;
    Source: string;
    showMessage?: boolean;
    showCustomOptions?:boolean;
    participantName?: string;
}

export interface MessageAttribute {
    index?: number;
    private?: boolean;
    visible_to?: string[];
    custom_message?: CustomMessage;
    status?: MessageStatus;
    messageCode?: string;
}

export interface CustomMessage {
    event_key: string;
    type: CustomMessageOptions;
    response_from: string;
    response_options: CustomButton[];
    response_value?: string;
}

export interface CustomButton {
    text: string;
    type?: string;
    action?: TimeExceedAction;
    skip_to_question?: number;
    queue_id?: number;
    skip_to_question_fail?: number;
    nextQuestionDetails?: any;
    url?: string;
}

export enum CustomMessageOptions {
    regular = 'regular',
    cobrowse_permission = 'cobrowse_permission',
    multiple_choice = 'multiple_choice'
}

export enum RedirectAction {
    redirect='redirect'
}
